<template>
  <el-row class="warp">
    <el-col :span="24" class="warp-breadcrum">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }"><b>首页</b></el-breadcrumb-item>
        <el-breadcrumb-item>内容管理</el-breadcrumb-item>
        <el-breadcrumb-item>其他页面</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
    <el-col :span="24" class="warp-main">

      <el-table :data="otherPage" highlight-current-row v-loading="listLoading" @selection-change="selsChange" style="width: 100%;" border>
        <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
        <el-table-column prop="name" label="页面" min-width="300" align="center"></el-table-column>
        <el-table-column prop="status" label="状态" width="120" align="center" sortable>
          <template slot-scope="scope" >
            <span v-bind:class="{temporary: (scope.row.status)==1}">{{scope.row.status==0?'已发布':'草稿'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="publisher" label="发布人" width="120" align="center"></el-table-column>
        <el-table-column prop="formatUpdateTime" label="修改时间" width="180" align="center" sortable>
          <template slot-scope="scope">
            <span>{{scope.row.formatUpdateTime ? scope.row.formatUpdateTime : "暂无更改"}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="160" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button size="small" v-if="permissionButton.view" @click="$router.push({path:'/otherPage/view',query:{id:scope.row.id}})">查看</el-button>
            <el-button type="primary" size="small" v-if="permissionButton.edit" @click="$router.push({path:'/otherPage/edit',query:{id:scope.row.id}})">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-col :span="24" class="table-footer">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10,15,20,25,50]"
          :page-size="pageSize"
          layout=" prev, pager, next"
          :total="total" style="float:right;">
        </el-pagination>
      </el-col>
    </el-col>
  </el-row>
</template>
<script>
  import {reqOtherPageListPage}  from '../../../api/otherPage-api'
  import permission from '../../../common/permission';

  export default{
    data(){
      return{
        loginStatus:'',
        otherPage:[],
        total:0,
        pageNum:1,
        pageSize:15,
        listLoading:false,
        sels:[],
        permissionButton:new permission.PermissionButton
      }
    },methods:{
      handleCurrentChange(val) {
        this.pageNum = val;
        this.currentPage = val;
        this.getOtherPageListPage();
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.pageSize=val;
        this.getOtherPageListPage();
      },

      getOtherPageListPage() {
        let para = {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        };
        this.listLoading = true;
        reqOtherPageListPage(para).then((res) => {
          this.total = res.data.data.total;
          this.otherPage = res.data.data.records;
          this.listLoading = false;
        })
      },
      selsChange: function (sels) {
        this.sels = sels;
      },

    },mounted(){
      this.getOtherPageListPage();
      this.loginStatus=sessionStorage.getItem('login');
      var permissionId = sessionStorage.getItem("permissionId");
      permission.getPermissionButton(permissionId,this.permissionButton);
    }
  }
</script>
